import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { DRAFT, REPORT_STATUS } from 'constants/reportStatus';
import {
  EPIC_REPORT_CREATION_PATH,
  EPIC_REPORT_VIEW_PATH,
  REPORT_CREATION_PATH,
  REPORT_VIEW_PATH,
} from 'constants/routePaths';

import useOPU from './useOPU';

const useViewReport = () => {
  const history = useHistory();

  const { opuActivated } = useOPU();

  const onViewReport = useCallback(
    report => {
      let viewPath = `${
        opuActivated?.isEPIC ? EPIC_REPORT_VIEW_PATH : REPORT_VIEW_PATH
      }/${report?.id}`;

      if (report?.actionId) {
        viewPath += `?actionId=${report.actionId}`;
      }

      history.push(viewPath);
    },
    [opuActivated?.isEPIC],
  );

  const onViewReportByName = useCallback(
    ({ id, original }) => {
      if (!opuActivated?.isEPIC) {
        if (original?.status?.id === REPORT_STATUS[DRAFT]?.id) {
          history.push(`${REPORT_CREATION_PATH}/${id}`);
        } else {
          history.push(`${REPORT_VIEW_PATH}/${id}`);
        }
      } else if (
        opuActivated?.isEPIC &&
        original?.status?.id === REPORT_STATUS[DRAFT]?.id
      ) {
        history.push(`${EPIC_REPORT_CREATION_PATH}/${id}`);
      } else {
        history.push(`${EPIC_REPORT_VIEW_PATH}/${id}`);
      }
    },
    [opuActivated?.isEPIC],
  );

  const onViewReportMyFeed = useCallback(
    ({ reportId, actionId, url }) => {
      const path =
        url ||
        (opuActivated?.isEPIC ? EPIC_REPORT_VIEW_PATH : REPORT_VIEW_PATH);

      window.location.replace(
        `${path}/${reportId}?actionId=${actionId}&isNewFeed=true`,
      );
    },
    [opuActivated?.isEPIC],
  );

  const onViewReportCreation = useCallback(
    ({ id }) => {
      if (opuActivated?.isEPIC) {
        history.push(`${EPIC_REPORT_CREATION_PATH}/${id}`);
      } else {
        history.push(`${REPORT_CREATION_PATH}/${id}`);
      }
    },
    [opuActivated?.isEPIC],
  );

  return {
    onViewReport,
    onViewReportByName,
    onViewReportMyFeed,
    onViewReportCreation,
  };
};

export default useViewReport;
