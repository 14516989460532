import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getData, patchData, postData, deleteData } from 'services/api';

import {
  DRILLDOWN_PATH,
  GET_KPIS_IN_FUNC,
  GET_SUGGEST_KPIS,
  KPI_PATH,
  DELETE_KPIS_FUNCTIONS,
  REFRESH_KPIS_IN_FUNC,
} from 'constants/apiPaths';
import { SOMETHING_WENT_WRONG } from 'constants/messages';

import {
  getTagKPIsRequest,
  getTagKPIsSuccess,
  getTagKPIsFailure,
  getSuggestKPIsRequest,
  getSuggestKPIsSuccess,
  getSuggestKPIsFailure,
  getKPIsRequest,
  getKPIsSuccess,
  getKPIsFailure,
  addKPIsRequest,
  addKPIsSuccess,
  addKPIsFailure,
  getKPIsInFuncRequest,
  getKPIsInFuncSuccess,
  getKPIsInFuncFailure,
  refreshKPIsInFuncRequest,
  refreshKPIsInFuncFailure,
  swapKPIsRequest,
  swapKPIsSuccess,
  swapKPIsFailure,
  deleteKPIsSuccess,
  deleteKPIsFailure,
  deleteKPIsRequest,
} from '../slices/kpi';
import { updateTimeOfReport } from '../slices/report';

import { convertObjectToParams } from '../services/format/uri';

export function* getTagKPIs({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: DRILLDOWN_PATH,
      params: payload,
    });

    const { IsSuccess, StatusCode, Data } = data;
    if (IsSuccess && StatusCode === 200) {
      yield put(getTagKPIsSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(getTagKPIsFailure(message));
  }
}

export function* getSuggestKPIs({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: `${GET_SUGGEST_KPIS}${convertObjectToParams(payload?.query)}`,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(400);
      yield put(getSuggestKPIsSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getSuggestKPIsFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* getKPIs({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: `${KPI_PATH}${convertObjectToParams(payload?.query)}`,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(getKPIsSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(getKPIsFailure({ error: responseMessage?.Message || message }));
  }
}

export function* addKPIs({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: KPI_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(
        addKPIsSuccess({
          functionId: payload?.data?.functionId,
          kpis: Data,
        }),
      );
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(addKPIsFailure({ error: responseMessage?.Message || message }));
  }
}

export function* getKPIsInFunc({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_KPIS_IN_FUNC,
      params: payload?.query,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(
        getKPIsInFuncSuccess({
          functionId: payload?.query?.functionId,
          kpis: Data,
        }),
      );
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getKPIsInFuncFailure({
        functionId: payload?.query?.functionId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* refreshKPIsInFunc({ payload }) {
  try {
    yield call(postData, {
      url: `${REFRESH_KPIS_IN_FUNC}/${payload?.query?.functionId}`,
    });
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data?.[0] || {};

    yield put(
      refreshKPIsInFuncFailure({
        functionId: payload?.query?.functionId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* swapKPIs({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: KPI_PATH,
      data: payload?.data,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(swapKPIsSuccess(payload?.data));
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      swapKPIsFailure({
        functionId: payload?.data?.functionId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* deleteKPIs({ payload }) {
  try {
    const { data } = yield call(deleteData, {
      url: `${DELETE_KPIS_FUNCTIONS}/${payload?.kpiId}`,
    });
    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(
        deleteKPIsSuccess({
          functionId: payload?.functionId,
          kpiId: payload?.kpiId,
        }),
      );
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    if (payload?.callback) {
      payload?.callback(false);
    }
    yield put(deleteKPIsFailure({ error: message }));
  }
}

export default function* kpiSaga() {
  yield takeLatest(getTagKPIsRequest().type, getTagKPIs);
  yield takeLatest(getSuggestKPIsRequest().type, getSuggestKPIs);
  yield takeEvery(getKPIsRequest().type, getKPIs);
  yield takeEvery(addKPIsRequest().type, addKPIs);
  yield takeEvery(getKPIsInFuncRequest().type, getKPIsInFunc);
  yield takeEvery(refreshKPIsInFuncRequest().type, refreshKPIsInFunc);
  yield takeEvery(swapKPIsRequest().type, swapKPIs);
  yield takeEvery(deleteKPIsRequest().type, deleteKPIs);
}
