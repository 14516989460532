import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  CATEGORIES,
  PINNED_CATEGORIES,
  PINNED_CATEGORIES_ON_LANDING,
  CATEGORIES_ON_REPORT_LISTING,
} from 'constants/props';
import { API_STATUS } from 'constants/apis';

export const ROOT_STATE_NAME = 'category';
const initialState = {
  [CATEGORIES]: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  [CATEGORIES_ON_REPORT_LISTING]: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  [PINNED_CATEGORIES]: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  [PINNED_CATEGORIES_ON_LANDING]: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  newCategory: {
    status: API_STATUS.IDLE,
    data: null,
    error: null,
  },
  updatePinnedCategories: {
    status: API_STATUS.IDLE,
    data: null,
    error: null,
  },
  suggestKeyword: {
    status: API_STATUS.IDLE,
    data: [],
    error: null,
  },
  deletedUnpinCategory: {
    status: API_STATUS.IDLE,
    data: null,
    error: null,
  },
  categoryDetail: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
};

const categorySlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getCategoriesRequest(state, action) {
      if (action.payload.isLoadMore && action.payload.query.pageIndex === 0) {
        state[action.payload.prop].data = { items: [] };
      }

      state[action.payload.prop].status = API_STATUS.LOADING;
    },
    getCategoriesSuccess(state, action) {
      if (action.payload.isLoadMore) {
        state[action.payload.prop].data = {
          ...action.payload.data,
          items:
            action.payload.query.pageIndex > 0
              ? [...state[action.payload.prop].data.items].concat(
                  action.payload.data.items,
                )
              : action.payload.data.items,
        };
      } else {
        state[action.payload.prop].data = action.payload.data;
      }

      state[action.payload.prop].status = API_STATUS.SUCCEEDED;
      state[action.payload.prop].error = null;
    },
    getCategoriesFailure(state, action) {
      state[action.payload.prop].error = action.payload.error;
      state[action.payload.prop].status = API_STATUS.FAILED;
    },
    addPinCategory(state, action) {
      state[action.payload.prop].data.items.unshift(action.payload.data);
    },
    deletePinCategory(state, action) {
      state[action.payload.prop].data.items = state[
        action.payload.prop
      ].data.items.filter(item => {
        if (item?.id === action.payload.data?.id) {
          return false;
        }

        return true;
      });
    },
    deleteUnpinCategoryRequest(state) {
      state.deletedUnpinCategory.status = API_STATUS.LOADING;
    },
    deleteUnpinCategorySuccess(state) {
      state.deletedUnpinCategory.status = API_STATUS.SUCCEEDED;
      state.deletedUnpinCategory.error = null;
    },
    deleteUnpinCategoryFailure(state, action) {
      state.deletedUnpinCategory.data = [];
      state.deletedUnpinCategory.status = API_STATUS.FAILED;
      state.deletedUnpinCategory.error = action.payload.error;
    },
    addOrUpdateCategoryRequest(state) {
      state.newCategory.status = API_STATUS.LOADING;
    },
    addOrUpdateCategorySuccess(state) {
      state.newCategory.status = API_STATUS.SUCCEEDED;
      state.newCategory.error = null;
    },
    addOrUpdateCategoryFailure(state, action) {
      state.newCategory.status = API_STATUS.FAILED;
      state.newCategory.error = action.payload.error;
    },
    updatePinnedCategoriesRequest(state) {
      state.updatePinnedCategories.status = API_STATUS.LOADING;
    },
    updatePinnedCategoriesSuccess(state) {
      state.updatePinnedCategories.status = API_STATUS.SUCCEEDED;
      state.updatePinnedCategories.error = null;
    },
    updatePinnedCategoriesFailure(state, action) {
      state.updatePinnedCategories.status = API_STATUS.FAILED;
      state.updatePinnedCategories.error = action.payload.error;
    },
    getSuggestKeywordRequest(state) {
      state.suggestKeyword.status = API_STATUS.LOADING;
    },
    getSuggestKeywordSuccess(state, action) {
      state.suggestKeyword.status = API_STATUS.SUCCEEDED;
      state.suggestKeyword.data = action.payload;
      state.suggestKeyword.error = null;
    },
    getSuggestKeywordFailure(state, action) {
      state.suggestKeyword.status = API_STATUS.FAILED;
      state.suggestKeyword.error = action.payload.error;
    },
    getCategoryDetailRequest(state) {
      state.categoryDetail.status = API_STATUS.LOADING;
    },
    getCategoryDetailSuccess(state, action) {
      state.categoryDetail.status = API_STATUS.SUCCEEDED;
      state.categoryDetail.data = action.payload;
      state.categoryDetail.error = null;
    },
    getCategoryDetailFailure(state, action) {
      state.categoryDetail.status = API_STATUS.FAILED;
      state.categoryDetail.error = action.payload.error;
    },
  },
});

export const {
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
  addPinCategory,
  deletePinCategory,
  deleteUnpinCategoryRequest,
  deleteUnpinCategorySuccess,
  deleteUnpinCategoryFailure,
  addOrUpdateCategoryRequest,
  addOrUpdateCategorySuccess,
  addOrUpdateCategoryFailure,
  updatePinnedCategoriesRequest,
  updatePinnedCategoriesSuccess,
  updatePinnedCategoriesFailure,
  getSuggestKeywordRequest,
  getSuggestKeywordSuccess,
  getSuggestKeywordFailure,
  getCategoryDetailRequest,
  getCategoryDetailSuccess,
  getCategoryDetailFailure,
} = categorySlice.actions;
export default categorySlice.reducer;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const categoriesByPropSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
export const newCategorySelector = createSelector(
  rootSelector,
  state => state.newCategory,
);
export const updatePinnedCategoriesSelector = createSelector(
  rootSelector,
  state => state.updatePinnedCategories,
);
export const suggestKeywordSelector = createSelector(
  rootSelector,
  state => state.suggestKeyword,
);
export const deletedUnpinCategorySelector = createSelector(
  rootSelector,
  state => state.deletedUnpinCategory,
);
export const categoryDetailSelector = createSelector(
  rootSelector,
  state => state.categoryDetail,
);
