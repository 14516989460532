export const AUTHENTICATION_API_PATH = 'v2/Authentication';
export const GET_OPU_PATH = 'v2/opu';
export const GET_CATEGORIES_PATH = 'v2/Category/GetCategories';
export const ADD_NEW_CATEGORIES_PATH = 'v2/Category/AddOrUpdateCategory';
export const UPDATE_PINNED_CATEGORIES_PATH = 'v2/Category/ManageCategory';
export const SUGGEST_KEYWORD_CATEGORIES_PATH = 'v2/Category/SuggestKeyword';
export const DELETE_CATEGORIES_PATH = 'v2/Category/DeleteCategory';
export const GET_CATEGORIES_DETAIL = 'v2/Category/GetCategory';
export const GET_CATEGORY_TYPES_PATH = 'v2/CategoryType/GetCategoryTypes';
export const ADD_CATEGORY_TYPE_PATH = 'v2/CategoryType/AddOrUpdateCategoryType';
export const REPORT_PATH = 'v2/Report';
export const DUPLICATE_REPORT_PATH = 'v2/Report/DuplicateReport';
export const GET_STAKEHOLDERS_BY_REPORT_PATH =
  'v2/Report/GetStakeHoldersByReport';
export const GET_BREADCRUMB = `${REPORT_PATH}/Breadcrumb`;
export const GET_REPORT_LISTING_PATH = 'v2/Report/GetReports';
export const GET_RECENT_REPORT_PATH = 'v2/Report/GetRecentReports';
export const GET_PDF = `${REPORT_PATH}/Pdf`;
export const GET_PDF_COVER = `${REPORT_PATH}/GetPDFCover`;
export const GET_NOTIFICATION_PATH = 'v2/Notification/GetNotification';
export const GET_UNREAD_REPORT_PATH = 'v2/Notification';
export const GET_IN_PROGRESS_REPORT_PATH = 'v2/Report/GetInProgressReports';
export const GET_PENDING_REPORT_PATH = 'v2/Report/GetPendingActionReports';
export const UPDATE_STATUS_REPORT_PATH = 'v2/Report/TakeAction';
export const REPUBLISH_REPORT = 'v2/Report/RePublishReport';
export const GET_UAM_PATH = 'v2/User/GetUserListByRole';
export const GET_COLLABORATORS_UAM_PATH = 'v2/User/GetEpicCollaborators';
export const INSIGHT_PATH = 'v2/Insight';
export const ADD_INSIGHT_PATH = `${INSIGHT_PATH}/AddInsight`;
export const GET_INSIGHT_PATH = `${INSIGHT_PATH}/GetInsightByReportId`;
export const DRILLDOWN_PATH = 'v2/Drilldown/GetTagKpis';
export const FUNCTION_PATH = 'v2/Function';
export const ADD_NEW_FUNCTION_PATH = 'v2/Function/AddFunction';
export const GET_FUNCTIONS_BY_OPU = `${FUNCTION_PATH}/GetMstFunctionByOpu`;
export const GET_FUNCTIONS = `${FUNCTION_PATH}/GetFunctionsFromMst`;
export const GET_FUNCTIONS_IN_REPORT = `${FUNCTION_PATH}/GetFunctionsInReport`;
export const GET_SUGGEST_FUNCTIONS = `${FUNCTION_PATH}/SuggestKeyword`;
export const MANAGE_ATTACHMENTS_FUNCTIONS = `${FUNCTION_PATH}/ManageKpiCategoryAttachments`;
export const DELETE_FUNCTION_ATTACHMENT = `${FUNCTION_PATH}/DeleteFunctionAttachment`;
export const KPI_PATH = 'v2/Kpi';
export const GET_SUGGEST_KPIS = `${KPI_PATH}/SuggestKeyword`;
export const DELETE_KPIS_FUNCTIONS = `${KPI_PATH}/DeleteKpis`;
export const GET_INFO_OF_KPI = `${KPI_PATH}/GetInfoOfKpi`;
export const GET_KPIS_IN_FUNC = `${KPI_PATH}/GetKPIsInFunction`;
export const REFRESH_KPIS_IN_FUNC = `${KPI_PATH}/RefreshByFunction`;
export const GET_CONTRIBUTING_IMPACTED_KPIS = `${KPI_PATH}/GetContributingImpactedKpis`;
export const PERFORMANCE_ANALYSES_PATH = 'v2/PerformanceAnalyses';
export const ADD_OR_UPDATE_PERFORMANCE_ANALYSIS = `${PERFORMANCE_ANALYSES_PATH}/AddOrEditPerformanceAnalysis`;
export const DELETE_PERFORMANCE_ANALYSIS_PATH = `${PERFORMANCE_ANALYSES_PATH}/DeletePerformanceAnalysis`;
export const REFRESH_PERFORMANCE_ANALYSIS_PATH = `${PERFORMANCE_ANALYSES_PATH}/RefreshPerfAnalysis`;
export const GET_ACTIVITY_LOG = 'v2/History/GetActivity';
export const ADD_OR_UPDATE_EXECUTIVE_SUMMARY_PATH = `${FUNCTION_PATH}/AddOrUpdateExecutiveSummary`;
export const GET_PERFORMANCE_ANALYSIS_PATH = `${PERFORMANCE_ANALYSES_PATH}/GetPerformanceAnalysis`;
export const GET_DATA_CHART_PATH = `${PERFORMANCE_ANALYSES_PATH}/GetDataChart`;
export const ADD_CONFIG_CHART_PATH = `${PERFORMANCE_ANALYSES_PATH}/AddChartConfigs`;
export const EDIT_CONFIG_CHART_PATH = `${PERFORMANCE_ANALYSES_PATH}/EditOptionsInChart`;
export const DELETE_DATA_VISUALISATION_CHART_PATH = `${PERFORMANCE_ANALYSES_PATH}/DeleteDataVisualisationChart`;
export const UPDATE_SHOW_CHART_STATUS_PATH = `${PERFORMANCE_ANALYSES_PATH}/EditDataVisualisation`;
export const GET_DRILL_DOWN_CONTRIBUTING_KPIS = `${PERFORMANCE_ANALYSES_PATH}/GetContributingData`;
export const CLARIFICATIONS_PATH = 'messages';
export const GET_CLARIFICATIONS_PATH = 'v2/clarification/GetClarifications';
export const GET_TOTAL_UNREAD_MESSAGES_PATH =
  'v2/clarification/GetTotalMessagesUnread';
export const READ_MESSAGE = 'v2/clarification/ReadMessage';
export const ADD_CLARIFICATIONS_PATH = 'v2/clarification/insertClarification';
export const GET_MEMBERS_IN_REPORTS_PATH = 'v2/Report/MembersInReport';
export const GET_LIST_USER_INFO_CLARIFICATION_PATH = 'users/list-user-info';
export const GET_DASHBOARD_URL_PATH = 'v2/Dashboard';
export const GET_KPIS_SAME_LEVEL = 'v2/Kpi/GetKPIInPerformanceAnalyze';
export const SAVE_FILTER_DRAFT_MODE = `${FUNCTION_PATH}/SaveFilter`;
