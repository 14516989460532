import React, { memo, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import useNewsReport from 'hooks/useNewsReports';

import {
  RECENTLY_FAVOURITE_REPORTS,
  RECENTLY_VISITED_REPORTS,
} from 'constants/props';
import { MY_RECENT_LOADING } from 'constants/loadingTypes';
import { GET_RECENT_REPORT_PATH } from 'constants/apiPaths';
import { API_STATUS } from 'constants/apis';

import SkeletonLoading from '../../skeleton/SkeletonLoading';
import RecentlyReportCard from '../RecentlyReportCard';

import './styles.scss';

const MyRecent = () => {
  const { reports: visitedReports, onGetReportsByProp: getVistedReports } =
    useNewsReport(RECENTLY_VISITED_REPORTS);
  const { reports: favouriteReports, onGetReportsByProp: getFavouriteReports } =
    useNewsReport(RECENTLY_FAVOURITE_REPORTS);

  useEffect(() => {
    getVistedReports({
      url: GET_RECENT_REPORT_PATH,
      query: { isFavorite: false },
      isLoadMore: false,
    });
    getFavouriteReports({
      url: GET_RECENT_REPORT_PATH,
      query: { isFavorite: true },
      isLoadMore: false,
    });
  }, []);

  return (
    <div className="pvda-my-recent">
      <div className="pvda-my-recent__header">
        <div
          style={{
            fontSize: 11,
            fontWeight: 500,
            textTransform: 'uppercase',
            letterSpacing: 1,
            color: '#9F9EAE',
            marginTop: 8,
          }}
        >
          Workspace
        </div>
      </div>
      <div className="pvda-my-recent__content">
        <div
          style={{
            fontWeight: 'normal',
            fontSize: 24,
            color: '#3F3C4C',
          }}
        >
          Recent
        </div>
        <Tabs
          defaultActiveKey="visited"
          transition={false}
          className="pvda-b-tabs"
        >
          <Tab eventKey="visited" title="Visited">
            <SkeletonLoading
              isLoading={visitedReports?.status === API_STATUS.LOADING}
              type={MY_RECENT_LOADING}
              counter={2}
            >
              {visitedReports?.status === API_STATUS.SUCCEEDED &&
                visitedReports.data?.map(report => (
                  <RecentlyReportCard
                    key={report.id}
                    {...report}
                    isFavorites={false}
                  />
                ))}
            </SkeletonLoading>
          </Tab>
          <Tab eventKey="favourites" title="Favourites">
            <SkeletonLoading
              isLoading={favouriteReports?.status === API_STATUS.LOADING}
              type={MY_RECENT_LOADING}
              counter={2}
            >
              {favouriteReports?.status === API_STATUS.SUCCEEDED &&
                favouriteReports.data?.map(report => (
                  <RecentlyReportCard key={report.id} {...report} isFavorites />
                ))}
            </SkeletonLoading>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

MyRecent.propTypes = {};

MyRecent.defaultProps = {};

export default memo(MyRecent);
