import { call, put, takeLatest } from 'redux-saga/effects';

import { getData } from 'services/api';

import {
  GET_CLARIFICATIONS_PATH,
  GET_TOTAL_UNREAD_MESSAGES_PATH,
} from 'constants/apiPaths';
import { SOMETHING_WENT_WRONG } from 'constants/messages';

import {
  getClarificationsRequest,
  getClarificationsSuccess,
  getClarificationsFailure,
  getTotalMessagesUnreadRequest,
  getTotalMessagesUnreadSuccess,
  getTotalMessagesUnreadFailure,
} from '../slices/clarification';

export function* getClarifications({ payload }) {
  const { query } = payload || {};

  try {
    const { data } = yield call(getData, {
      url: GET_CLARIFICATIONS_PATH,
      params: query,
    });
    const { IsSuccess, Data } = data;

    if (IsSuccess) {
      yield put(
        getClarificationsSuccess({
          data: Data,
          query,
        }),
      );
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(getClarificationsFailure(responseMessage?.Message || message));
  }
}

export function* getTotalMessagesUnread({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_TOTAL_UNREAD_MESSAGES_PATH,
      params: payload?.query,
    });

    const { IsSuccess, Data } = data;

    if (IsSuccess) {
      yield put(getTotalMessagesUnreadSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getTotalMessagesUnreadFailure(responseMessage?.Message || message),
    );
  }
}

export default function* clarificationSaga() {
  yield takeLatest(getClarificationsRequest().type, getClarifications);
  yield takeLatest(
    getTotalMessagesUnreadRequest().type,
    getTotalMessagesUnread,
  );
}
