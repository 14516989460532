import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { CONTRIBUTING_KPIS, IMPACTED_KPIS } from 'constants/props';
import { API_STATUS } from 'constants/apis';

export const ROOT_STATE_NAME = 'kpi';
const initialState = {
  kpis: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  kpi: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  suggestKPIs: {
    status: API_STATUS.IDLE,
    data: [],
    error: null,
  },
  addKPIs: {
    status: API_STATUS.IDLE,
    error: null,
  },
  kpisInFuncs: {},
  swapKPIs: {},
  tagKPIs: {
    status: API_STATUS.IDLE,
    data: [],
    error: null,
  },
  deleteKPIs: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  updateForecastData: {
    status: API_STATUS.IDLE,
    error: null,
  },
  listPerfAnalysis: {},
  addOrUpdatePerfAnalysis: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  deletedPerfAnalysis: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  kpiInfo: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  breadcrumbs: {
    status: API_STATUS.IDLE,
    data: [],
    error: null,
  },
  activityLog: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  dataChart: {},
  deletedVisualizationChart: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  [CONTRIBUTING_KPIS]: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  [IMPACTED_KPIS]: {
    status: API_STATUS.IDLE,
    data: {},
    error: null,
  },
  dashboardUrl: {
    status: API_STATUS.IDLE,
    data: '',
    error: null,
  },
  visualizationStatus: {},
  kpisSameLevel: {
    status: API_STATUS.IDLE,
    data: [],
    error: null,
  },
};

const kpiSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getKPIsRequest(state) {
      state.kpis.status = API_STATUS.LOADING;
    },
    getKPIsSuccess(state, action) {
      state.kpis.data = action.payload;
      state.kpis.status = API_STATUS.SUCCEEDED;
      state.kpis.error = null;
    },
    getKPIsFailure(state, action) {
      state.kpis.status = API_STATUS.FAILED;
      state.kpis.data = [];
      state.kpis.error = action.payload.error;
    },
    getKPIRequest(state) {
      state.kpi.status = API_STATUS.LOADING;
    },
    getKPISuccess(state, action) {
      state.kpi.data = action.payload;
      state.kpi.status = API_STATUS.SUCCEEDED;
      state.kpi.error = null;
    },
    getKPIFailure(state, action) {
      state.kpi.status = API_STATUS.FAILED;
      state.kpi.data = {};
      state.kpi.error = action.payload.error;
    },
    resetKPI(state) {
      state.kpi.status = API_STATUS.IDLE;
      state.kpi.data = {};
      state.kpi.error = null;

      state[CONTRIBUTING_KPIS].status = API_STATUS.IDLE;
      state[CONTRIBUTING_KPIS].data = {};
      state[CONTRIBUTING_KPIS].error = null;

      state[IMPACTED_KPIS].status = API_STATUS.IDLE;
      state[IMPACTED_KPIS].data = {};
      state[IMPACTED_KPIS].error = null;
    },
    updateKPI(state, action) {
      state.kpi.data = {
        ...state.kpi.data,
        performanceAnalysesForTable: action.payload.tableData,
        performanceAnalysesForChart: action.payload.chartData,
      };
    },
    getSuggestKPIsRequest(state) {
      state.suggestKPIs.status = API_STATUS.LOADING;
    },
    getSuggestKPIsSuccess(state, action) {
      state.suggestKPIs.data = action.payload;
      state.suggestKPIs.status = API_STATUS.SUCCEEDED;
      state.suggestKPIs.error = null;
    },
    getSuggestKPIsFailure(state, action) {
      state.suggestKPIs.status = API_STATUS.FAILED;
      state.suggestKPIs.data = [];
      state.suggestKPIs.error = action.payload.error;
    },
    addKPIsRequest(state) {
      state.addKPIs.status = API_STATUS.LOADING;
    },
    addKPIsSuccess(state, action) {
      const { functionId, kpis } = action.payload;
      state.kpisInFuncs[functionId] = { data: kpis };
      state.addKPIs.status = API_STATUS.SUCCEEDED;
      state.addKPIs.error = null;
    },
    addKPIsFailure(state, action) {
      state.addKPIs.status = API_STATUS.FAILED;
      state.addKPIs.error = action.payload.error;
    },
    getKPIsInFuncRequest(state, action) {
      const { functionId } = action.payload?.query;
      state.kpisInFuncs[functionId] = {
        status: API_STATUS.LOADING,
        data: state.kpisInFuncs[functionId]?.data || {},
      };
    },
    getKPIsInFuncSuccess(state, action) {
      const { functionId, kpis } = action.payload;
      state.kpisInFuncs[functionId] = {
        status: API_STATUS.SUCCEEDED,
        data: kpis,
        error: null,
      };
    },
    getKPIsInFuncFailure(state, action) {
      const { functionId, error } = action.payload;
      state.kpisInFuncs[functionId] = {
        status: API_STATUS.FAILED,
        data: {},
        error,
      };
    },
    refreshKPIsInFuncRequest(state, action) {
      const { functionId } = action.payload?.query;
      state.kpisInFuncs[functionId] = {
        status: API_STATUS.LOADING,
        data: state.kpisInFuncs[functionId]?.data || {},
      };
    },
    refreshKPIsInFuncSuccess(state, action) {
      const { functionId, kpis } = action.payload;
      state.kpisInFuncs[functionId] = {
        status: API_STATUS.SUCCEEDED,
        data: kpis,
        error: null,
      };
    },
    refreshKPIsInFuncFailure(state, action) {
      const { functionId, error } = action.payload;
      state.kpisInFuncs[functionId] = {
        status: API_STATUS.FAILED,
        data: {},
        error,
      };
    },
    swapKPIsRequest(state, action) {
      const { functionId } = action.payload?.data;
      state.swapKPIs[functionId] = { status: API_STATUS.LOADING };
    },
    swapKPIsSuccess(state, action) {
      const { functionId, fromKPI, toKPI } = action.payload;
      const currentKPIs = state.kpisInFuncs[functionId].data.rows;
      // find idx by kpi id then swapping it
      const fromIdx = currentKPIs.findIndex(({ id }) => id === fromKPI);
      const toIdx = currentKPIs.findIndex(({ id }) => id === toKPI);

      const tempKPI = { ...currentKPIs[toIdx] };
      currentKPIs[toIdx] = { ...currentKPIs[fromIdx] };
      currentKPIs[fromIdx] = tempKPI;

      state.kpisInFuncs[functionId].data.rows = currentKPIs;
      state.swapKPIs[functionId] = {
        status: API_STATUS.SUCCEEDED,
        error: null,
      };
    },
    swapKPIsFailure(state, action) {
      const { functionId, error } = action.payload;
      state.swapKPIs[functionId] = {
        status: API_STATUS.FAILED,
        error,
      };
    },
    getTagKPIsRequest(state) {
      state.tagKPIs.status = API_STATUS.LOADING;
    },
    getTagKPIsSuccess(state, action) {
      state.tagKPIs.data = action.payload;
      state.tagKPIs.status = API_STATUS.SUCCEEDED;
      state.tagKPIs.error = null;
    },
    getTagKPIsFailure(state, action) {
      state.tagKPIs.status = API_STATUS.FAILED;
      state.tagKPIs.data = [];
      state.tagKPIs.error = action.payload;
    },
    deleteKPIsRequest(state) {
      state.deleteKPIs.status = API_STATUS.LOADING;
    },
    deleteKPIsSuccess(state, action) {
      const { functionId, kpiId } = action?.payload;
      state.kpisInFuncs[functionId].data.rows = state.kpisInFuncs[
        functionId
      ]?.data?.rows.filter(x => x.id !== kpiId);
      state.deleteKPIs.status = API_STATUS.SUCCEEDED;
      state.deleteKPIs.error = null;
    },
    deleteKPIsFailure(state, action) {
      state.deleteKPIs.status = API_STATUS.FAILED;
      state.deleteKPIs.data = [];
      state.deleteKPIs.error = action.payload.error;
    },
    updateForecastDataRequest(state) {
      state.updateForecastData.status = API_STATUS.LOADING;
    },
    updateForecastDataSuccess(state) {
      state.updateForecastData.status = API_STATUS.SUCCEEDED;
      state.updateForecastData.error = null;
    },
    updateForecastDataFailure(state, action) {
      state.updateForecastData.status = API_STATUS.FAILED;
      state.updateForecastData.error = action.payload.error;
    },
    getPerfAnalysisRequest(state, action) {
      const { kpiId } = action.payload;
      state.listPerfAnalysis[kpiId] = { status: API_STATUS.LOADING };
    },
    getPerfAnalysisSuccess(state, action) {
      const { kpiId, data } = action.payload;
      state.listPerfAnalysis[kpiId] = {
        status: API_STATUS.SUCCEEDED,
        data,
      };
    },
    updatePerfAnalysis(state, action) {
      const { kpiId, data } = action.payload;
      state.listPerfAnalysis[kpiId] = {
        ...state.listPerfAnalysis[kpiId],
        data,
      };
    },
    getPerfAnalysisFailure(state, action) {
      const { kpiId, error } = action.payload;
      state.listPerfAnalysis[kpiId] = {
        status: API_STATUS.FAILED,
        error,
      };
    },
    addOrUpdatePerfAnalysisRequest(state) {
      state.addOrUpdatePerfAnalysis.status = API_STATUS.LOADING;
    },
    addOrUpdatePerfAnalysisSuccess(state, action) {
      const { kpiId, data } = action.payload;
      const idxPerfAnalaysis = state.listPerfAnalysis[kpiId].data.findIndex(
        perf => perf.id === data.id,
      );

      if (idxPerfAnalaysis !== -1) {
        state.listPerfAnalysis[kpiId].data[idxPerfAnalaysis] = data;
      } else {
        state.listPerfAnalysis[kpiId].data = [
          { ...data },
          ...state.listPerfAnalysis[kpiId].data,
        ];
      }

      state.addOrUpdatePerfAnalysis.status = API_STATUS.SUCCEEDED;
      state.addOrUpdatePerfAnalysis.error = null;
    },
    addOrUpdatePerfAnalysisFailure(state, action) {
      state.addOrUpdatePerfAnalysis.status = API_STATUS.FAILED;
      state.addOrUpdatePerfAnalysis.data = {};
      state.addOrUpdatePerfAnalysis.error = action.payload.error;
    },
    deletePerfAnalysisRequest(state) {
      state.deletedPerfAnalysis.status = API_STATUS.LOADING;
    },
    deletePerfAnalysisSuccess(state, action) {
      const { kpiId, id } = action.payload;
      state.listPerfAnalysis[kpiId].data = state.listPerfAnalysis[
        kpiId
      ].data.filter(x => x.id !== id);
      state.deletedPerfAnalysis.status = API_STATUS.SUCCEEDED;
      state.deletedPerfAnalysis.error = null;
    },
    deletePerfAnalysisFailure(state, action) {
      state.deletedPerfAnalysis.status = API_STATUS.FAILED;
      state.deletedPerfAnalysis.data = {};
      state.deletedPerfAnalysis.error = action.payload.error;
    },
    getInfoOfKpiRequest(state) {
      state.kpiInfo.status = API_STATUS.LOADING;
    },
    getInfoOfKpiSuccess(state, action) {
      state.kpiInfo.status = API_STATUS.SUCCEEDED;
      state.kpiInfo.error = null;
      state.kpiInfo.data = action.payload;
    },
    getInfoOfKpiFailure(state, action) {
      state.kpiInfo.status = API_STATUS.FAILED;
      state.kpiInfo.error = action.payload.error;
    },
    getBreadcrumbsRequest(state) {
      state.breadcrumbs.status = API_STATUS.LOADING;
      state.breadcrumbs.data = [];
    },
    getBreadcrumbsSuccess(state, action) {
      state.breadcrumbs.data = action.payload;
      state.breadcrumbs.status = API_STATUS.SUCCEEDED;
      state.breadcrumbs.error = null;
    },
    getBreadcrumbsFailure(state, action) {
      state.breadcrumbs.status = API_STATUS.FAILED;
      state.breadcrumbs.data = [];
      state.breadcrumbs.error = action.payload.error;
    },
    getActivityLogRequest(state, action) {
      if (action.payload?.pageIndex === 0) {
        state.activityLog.data = { items: [] };
      }
      state.activityLog.status = API_STATUS.LOADING;
    },
    getActivityLogSuccess(state, action) {
      if (action.payload?.query?.pageIndex === 0) {
        state.activityLog.data = action.payload.data || { items: [] };
      } else {
        state.activityLog.data = {
          ...action.payload.data,
          items: state.activityLog.data?.items.concat(
            action.payload.data?.items,
          ),
        };
      }
      state.activityLog.status = API_STATUS.SUCCEEDED;
      state.activityLog.error = null;
    },
    getActivityLogFailure(state, action) {
      state.activityLog.status = API_STATUS.FAILED;
      state.activityLog.data = [];
      state.activityLog.error = action.payload.error;
    },
    newActivityLog(state, action) {
      if (state.activityLog.data.totalCount >= 0) {
        const newActivity = action.payload;

        state.activityLog.data.items.unshift(newActivity);
      }
    },
    getKPIsByPropRequest(state, action) {
      state[action.payload.prop].status = API_STATUS.LOADING;
    },
    getKPIsByPropSuccess(state, action) {
      state[action.payload.prop].data = action.payload.data;
      state[action.payload.prop].status = API_STATUS.SUCCEEDED;
      state[action.payload.prop].error = null;
    },
    getKPIsByPropFailure(state, action) {
      state[action.payload.prop].status = API_STATUS.FAILED;
      state[action.payload.prop].data = {};
      state[action.payload.prop].error = action.payload.error;
    },
    getDataChartRequest(state, action) {
      const { kpiId } = action.payload?.query;
      state.dataChart[kpiId] = { status: API_STATUS.LOADING };
    },
    getDataChartSuccess(state, action) {
      const { kpiId, data = {} } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.SUCCEEDED,
        data,
        error: null,
      };
    },
    getDataChartFailure(state, action) {
      const { kpiId, error } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.FAILED,
        data: {},
        error,
      };
    },
    addConfigChartRequest() {},
    addConfigChartSuccess(state, action) {
      const { kpiId, data } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.SUCCEEDED,
        data: {
          ...state.dataChart[kpiId]?.data,
          chartConfigs: {
            chartConfig: data,
            isShowChart: true,
          },
        },
        error: null,
      };
    },
    addConfigChartFailure(state, action) {
      const { kpiId, error } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.FAILED,
        data: {},
        error,
      };
    },
    editConfigChartRequest() {},
    editConfigChartSuccess(state, action) {
      const { kpiId, data } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.SUCCEEDED,
        data: {
          ...state.dataChart[kpiId]?.data,
          chartConfigs: {
            chartConfig: data,
            isShowChart: true,
          },
        },
        error: null,
      };
    },
    editConfigChartFailure(state, action) {
      const { kpiId, error } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.FAILED,
        data: {},
        error,
      };
    },
    deleteDataVisualisationChartRequest(state) {
      state.deletedVisualizationChart.status = API_STATUS.LOADING;
    },
    deleteDataVisualisationChartSuccess(state, action) {
      const { kpiId, type } = action.payload;
      state.deletedVisualizationChart.status = API_STATUS.SUCCEEDED;
      state.dataChart[kpiId] = {
        data: {
          ...state.dataChart[kpiId]?.data,
          chartConfigs: {
            ...state.dataChart[kpiId]?.data?.chartConfigs,
            chartConfig: state.dataChart[
              kpiId
            ].data.chartConfigs?.chartConfig?.filter(x => x.type !== type),
          },
        },
      };
      state.deletedVisualizationChart.error = null;
    },
    deleteDataVisualisationChartFailure(state, action) {
      const { error } = action.payload;
      state.deletedVisualizationChart.status = API_STATUS.FAILED;
      state.deletedVisualizationChart.data = {};
      state.deletedVisualizationChart.error = error;
    },
    getDashboardUrlRequest(state) {
      state.dashboardUrl.status = API_STATUS.LOADING;
    },
    getDashboardUrlSuccess(state, action) {
      state.dashboardUrl.data = action.payload?.data;
      state.dashboardUrl.status = API_STATUS.SUCCEEDED;
      state.dashboardUrl.error = null;
    },
    getDashboardUrlFailure(state, action) {
      state.dashboardUrl.status = API_STATUS.FAILED;
      state.dashboardUrl.data = '';
      state.dashboardUrl.error = action.payload.error;
    },
    updateShowChartStatusRequest(state, action) {
      const { kpiId } = action.payload.data;
      state.visualizationStatus[kpiId] = { status: API_STATUS.LOADING };
    },
    updateShowChartStatusSuccess(state, action) {
      const { kpiId, data } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.SUCCEEDED,
        data: {
          ...state.dataChart[kpiId]?.data,
          chartConfigs: {
            ...state.dataChart[kpiId]?.data?.chartConfigs,
            isShowChart: data?.isShowChart,
          },
        },
        error: null,
      };
      state.visualizationStatus[kpiId] = { status: API_STATUS.SUCCEEDED };
    },
    updateShowChartStatusFailure(state, action) {
      const { kpiId, error } = action.payload;
      state.dataChart[kpiId] = {
        status: API_STATUS.FAILED,
        data: {},
        error,
      };
      state.visualizationStatus[kpiId] = {
        status: API_STATUS.FAILED,
        error: action.payload.error,
      };
    },
    refreshKPI(state, action) {
      const { functionId, kpiId, newKPI } = action.payload;
      const idx = state.kpisInFuncs[functionId]?.data?.rows.findIndex(
        row => row.id === kpiId,
      );

      state.kpisInFuncs[functionId].data.rows[idx] = newKPI;
    },
    getKPIsSameLevelRequest(state) {
      state.dashboardUrl.status = API_STATUS.LOADING;
    },
    getKPIsSameLevelSuccess(state, action) {
      state.kpisSameLevel.data = action.payload?.data;
      state.kpisSameLevel.status = API_STATUS.SUCCEEDED;
      state.kpisSameLevel.error = null;
    },
    getKPIsSameLevelFailure(state, action) {
      state.kpisSameLevel.status = API_STATUS.FAILED;
      state.kpisSameLevel.data = '';
      state.kpisSameLevel.error = action.payload.error;
    },
  },
});

export const {
  getKPIsRequest,
  getKPIsSuccess,
  getKPIsFailure,
  getKPIRequest,
  getKPISuccess,
  getKPIFailure,
  resetKPI,
  updateKPI,
  getSuggestKPIsRequest,
  getSuggestKPIsSuccess,
  getSuggestKPIsFailure,
  addKPIsRequest,
  addKPIsSuccess,
  addKPIsFailure,
  getKPIsInFuncRequest,
  getKPIsInFuncSuccess,
  getKPIsInFuncFailure,
  refreshKPIsInFuncRequest,
  refreshKPIsInFuncSuccess,
  refreshKPIsInFuncFailure,
  swapKPIsRequest,
  swapKPIsSuccess,
  swapKPIsFailure,
  getTagKPIsRequest,
  getTagKPIsSuccess,
  getTagKPIsFailure,
  deleteKPIsRequest,
  deleteKPIsSuccess,
  deleteKPIsFailure,
  updateForecastDataRequest,
  updateForecastDataSuccess,
  updateForecastDataFailure,
  getPerfAnalysisRequest,
  getPerfAnalysisSuccess,
  getPerfAnalysisFailure,
  updatePerfAnalysis,
  addOrUpdatePerfAnalysisRequest,
  addOrUpdatePerfAnalysisSuccess,
  addOrUpdatePerfAnalysisFailure,
  deletePerfAnalysisRequest,
  deletePerfAnalysisSuccess,
  deletePerfAnalysisFailure,
  getInfoOfKpiRequest,
  getInfoOfKpiSuccess,
  getInfoOfKpiFailure,
  getBreadcrumbsRequest,
  getBreadcrumbsSuccess,
  getBreadcrumbsFailure,
  getActivityLogRequest,
  getActivityLogSuccess,
  getActivityLogFailure,
  newActivityLog,
  getKPIsByPropRequest,
  getKPIsByPropSuccess,
  getKPIsByPropFailure,
  getDataChartRequest,
  getDataChartSuccess,
  getDataChartFailure,
  addConfigChartRequest,
  addConfigChartSuccess,
  addConfigChartFailure,
  editConfigChartRequest,
  editConfigChartSuccess,
  editConfigChartFailure,
  getDashboardUrlRequest,
  getDashboardUrlSuccess,
  getDashboardUrlFailure,
  updateShowChartStatusRequest,
  updateShowChartStatusSuccess,
  updateShowChartStatusFailure,
  deleteDataVisualisationChartRequest,
  deleteDataVisualisationChartSuccess,
  deleteDataVisualisationChartFailure,
  getKPIsSameLevelRequest,
  getKPIsSameLevelSuccess,
  getKPIsSameLevelFailure,
  refreshKPI,
} = kpiSlice.actions;
export default kpiSlice.reducer;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const kpisSelector = createSelector(rootSelector, state => state.kpis);
export const kpiSelector = createSelector(rootSelector, state => state.kpi);
export const kpiInfoSelector = createSelector(
  rootSelector,
  ({ kpiInfo }) => kpiInfo,
);
export const suggestKPIsSelector = createSelector(
  rootSelector,
  state => state.suggestKPIs,
);
export const addKPIsSelector = createSelector(
  rootSelector,
  ({ addKPIs }) => addKPIs,
);
export const kpisInFuncSelector = functionId =>
  createSelector(rootSelector, state => state.kpisInFuncs[functionId]);
export const swapKPIsSelector = functionId =>
  createSelector(rootSelector, state => state.swapKPIs[functionId]);
export const tagKPIsSelector = createSelector(
  rootSelector,
  state => state.tagKPIs,
);
export const deleteKPIsSelector = createSelector(
  rootSelector,
  ({ deleteKPIs }) => deleteKPIs,
);
export const updateForecastDataSelector = createSelector(
  rootSelector,
  ({ updateForecastData }) => updateForecastData,
);
export const listPerfAnalysisSelector = kpiId =>
  createSelector(
    rootSelector,
    ({ listPerfAnalysis }) => listPerfAnalysis[kpiId],
  );
export const breadcrumbsSelector = createSelector(
  rootSelector,
  ({ breadcrumbs }) => breadcrumbs,
);
export const activityLogSelector = createSelector(
  rootSelector,
  ({ activityLog }) => activityLog,
);
export const dashboardUrlSelector = createSelector(
  rootSelector,
  ({ dashboardUrl }) => dashboardUrl,
);
export const dataChartSelector = kpiId =>
  createSelector(rootSelector, ({ dataChart }) => dataChart[kpiId]);
export const deleteVisualizationChartSelector = createSelector(
  rootSelector,
  ({ deletedVisualizationChart }) => deletedVisualizationChart,
);
export const kpisByPropSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
export const addOrUpdatePerfAnalysisSelector = createSelector(
  rootSelector,
  state => state.addOrUpdatePerfAnalysis,
);
export const visualizationStatusSelector = kpiId =>
  createSelector(
    rootSelector,
    ({ visualizationStatus }) => visualizationStatus[kpiId],
  );

export const kpisSameLevelSelector = createSelector(
  rootSelector,
  ({ kpisSameLevel }) => kpisSameLevel,
);
