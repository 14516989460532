import { lazy } from 'react';

import {
  LANDING_PATH,
  AUTH_PATH,
  CALLBACK_AUTH_PATH,
  REPORT_LISTING_PATH,
  REPORT_LISTING_ID_PATH,
  REPORT_CREATION_ID_PATH,
  REPORT_VIEW_ID_PATH,
  EPIC_REPORT_CREATION_ID_PATH,
  EPIC_REPORT_VIEW_ID_PATH,
  PERFORMANCE_ANALYSIS_PATH,
  EPIC_CATEGORY_REQUEST_ACCESS_PATH,
  ERROR_PATH,
  NOT_FOUND_PATH,
} from 'constants/routePaths';

const routes = [
  {
    path: LANDING_PATH,
    component: lazy(() => import('pages/LandingPage')),
    exact: true,
    requiredAuthen: true,
  },
  {
    path: AUTH_PATH,
    component: lazy(() => import('pages/AuthPage')),
    exact: true,
  },
  {
    path: CALLBACK_AUTH_PATH,
    component: lazy(() => import('pages/CallbackAuthPage')),
    exact: true,
  },
  {
    path: REPORT_LISTING_PATH,
    component: lazy(() => import('pages/ReportListingPage')),
    exact: true,
    requiredAuthen: true,
  },
  {
    path: REPORT_LISTING_ID_PATH,
    component: lazy(() => import('pages/ReportListingPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: REPORT_CREATION_ID_PATH,
    component: lazy(() => import('pages/ReportCreationPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: REPORT_VIEW_ID_PATH,
    component: lazy(() => import('./pages/ReportViewPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: PERFORMANCE_ANALYSIS_PATH,
    component: lazy(() => import('pages/KPIDetailPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: EPIC_REPORT_CREATION_ID_PATH,
    component: lazy(() => import('pages/ReportCreationEpicPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: EPIC_REPORT_VIEW_ID_PATH,
    component: lazy(() => import('pages/ReportViewEpicPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: EPIC_CATEGORY_REQUEST_ACCESS_PATH,
    component: lazy(() => import('pages/CategoryRequestAccessEpicPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: ERROR_PATH,
    component: lazy(() => import('pages/ErrorPage')),
    requiredAuthen: true,
    exact: false,
  },
  {
    path: NOT_FOUND_PATH,
    component: lazy(() => import('pages/NotFoundPage')),
    requiredAuthen: true,
    exact: false,
  },
];

export default routes;
