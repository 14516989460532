export const LANDING_PATH = '/';
export const AUTH_PATH = '/auth';
export const CALLBACK_AUTH_PATH = '/auth/callback';
export const REPORT_LISTING_PATH = '/report-listing';
export const REPORT_LISTING_ID_PATH = '/report-listing/:categoryId';
export const REPORT_CREATION_PATH = '/report-creation';
export const REPORT_CREATION_ID_PATH = '/report-creation/:reportId';
export const REPORT_VIEW_PATH = '/report-view';
export const REPORT_VIEW_ID_PATH = '/report-view/:reportId';
export const EPIC_REPORT_CREATION_PATH = '/epic-report-creation';
export const EPIC_REPORT_CREATION_ID_PATH = '/epic-report-creation/:reportId';
export const EPIC_REPORT_VIEW_PATH = '/epic-report-view';
export const EPIC_REPORT_VIEW_ID_PATH = '/epic-report-view/:reportId';
export const PERFORMANCE_ANALYSIS_PATH =
  '/performance-analysis/:reportId/:kpiId';
export const EPIC_CATEGORY_REQUEST_ACCESS_PATH =
  '/epic-category-request-access';
export const ERROR_PATH = '/error-page';
export const NOT_FOUND_PATH = '*';
