import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getData, postData, putData, deleteData } from 'services/api';

import {
  GET_INSIGHT_PATH,
  ADD_INSIGHT_PATH,
  INSIGHT_PATH,
} from 'constants/apiPaths';
import { SOMETHING_WENT_WRONG } from 'constants/messages';

import {
  getInsightsRequest,
  getInsightsSuccess,
  getInsightsFailure,
  addInsightRequest,
  addInsightSuccess,
  addInsightFailure,
  editInsightRequest,
  editInsightSuccess,
  editInsightFailure,
  deleteInsightRequest,
  deleteInsightSuccess,
  deleteInsightFailure,
} from '../slices/insight';

import { updateTimeOfReport } from '../slices/report';

export function* getInsights({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: `${GET_INSIGHT_PATH}/${payload?.reportId}`,
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(getInsightsSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(getInsightsFailure(message));
  }
}

export function* addInsight({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: ADD_INSIGHT_PATH,
      data: payload?.data,
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(addInsightSuccess(Data || {}));
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      addInsightFailure({ error: responseMessage?.Message || message }),
    );

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* editInsight({ payload }) {
  try {
    const { data } = yield call(putData, {
      url: INSIGHT_PATH,
      data: payload?.data,
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(editInsightSuccess(Data || {}));
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      editInsightFailure({ error: responseMessage?.Message || message }),
    );

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* deleteInsight({ payload }) {
  try {
    const { data } = yield call(deleteData, {
      url: `${INSIGHT_PATH}/${payload?.reportId}/${payload?.insightId}`,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(deleteInsightSuccess({ insightId: payload?.insightId }));
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(deleteInsightFailure(message));

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export default function* insightSaga() {
  yield takeLatest(getInsightsRequest().type, getInsights);
  yield takeEvery(addInsightRequest().type, addInsight);
  yield takeEvery(editInsightRequest().type, editInsight);
  yield takeEvery(deleteInsightRequest().type, deleteInsight);
}
