import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  RECENTLY_UPDATED_REPORTS,
  RECENTLY_VISITED_REPORTS,
  RECENTLY_FAVOURITE_REPORTS,
  PENDING_REPORTS,
  IN_PROGRESS_REPORTS,
} from 'constants/props';
import { API_STATUS } from 'constants/apis';

export const ROOT_STATE_NAME = 'newsReport';
const initialState = {
  [RECENTLY_UPDATED_REPORTS]: {
    data: [],
    status: API_STATUS.IDLE,
    error: null,
  },
  [RECENTLY_VISITED_REPORTS]: {
    data: [],
    status: API_STATUS.IDLE,
    error: null,
  },
  [RECENTLY_FAVOURITE_REPORTS]: {
    data: [],
    status: API_STATUS.IDLE,
    error: null,
  },
  [PENDING_REPORTS]: {
    data: { items: [] },
    status: API_STATUS.IDLE,
    error: null,
  },
  [IN_PROGRESS_REPORTS]: {
    data: { items: [] },
    status: API_STATUS.IDLE,
    error: null,
  },
  totalUnreadReport: {
    data: 0,
    status: API_STATUS.IDLE,
    error: null,
  },
};

const NewsReportsSilece = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getReportsByPropRequest(state, action) {
      if (action.payload.isLoadMore && action.payload.query.pageIndex === 0) {
        state[action.payload.prop].data = { items: [] };
      }

      state[action.payload.prop].status = API_STATUS.LOADING;
    },
    getReportsByPropSuccess(state, action) {
      if (action.payload.isLoadMore) {
        state[action.payload.prop].data = {
          ...action.payload.data,
          items:
            action.payload.query.pageIndex > 0
              ? state[action.payload.prop].data.items.concat(
                  action.payload.data.items,
                )
              : action.payload.data.items,
          totalCount:
            action.payload.query.pageIndex > 0
              ? state[action.payload.prop].data.totalCount
              : action.payload.data.totalCount,
          unreadAction:
            action.payload.query.pageIndex > 0
              ? state[action.payload.prop].data.unreadAction
              : action.payload.data.unreadAction,
        };
      } else {
        state[action.payload.prop].data =
          action.payload.data.items || action.payload.data; // Will remove action.payload.data.items after integrate with BE
      }

      if (action.payload.prop === RECENTLY_UPDATED_REPORTS) {
        state.totalUnreadReport.data =
          state[action.payload.prop].data.unreadAction;
      }

      state[action.payload.prop].status = API_STATUS.SUCCEEDED;
      state[action.payload.prop].error = null;
    },
    getReportsByPropFailure(state, action) {
      state[action.payload.prop].status = API_STATUS.FAILED;
      state[action.payload.prop].data = [];
      state[action.payload.prop].error = action.payload.error;
    },
    getTotalUnreadReportRequest(state) {
      state.totalUnreadReport.status = API_STATUS.LOADING;
    },
    getTotalUnreadReportSuccess(state, action) {
      state.totalUnreadReport.data = action.payload;
      state.totalUnreadReport.status = API_STATUS.SUCCEEDED;
      state.totalUnreadReport.error = null;
    },
    getTotalUnreadReportFailure(state, action) {
      state.totalUnreadReport.status = API_STATUS.FAILED;
      state.totalUnreadReport.data = 0;
      state.totalUnreadReport.error = action.payload;
    },
    updateReadReport(state, action) {
      const reports = state[RECENTLY_UPDATED_REPORTS].data.items;

      for (let i = 0; i < reports.length; i++) {
        if (
          reports[i].id === action.payload?.reportId &&
          reports[i].actionId === action.payload?.actionId
        ) {
          reports[i].isRead = true;
          break;
        }
      }

      state[RECENTLY_UPDATED_REPORTS].data.items = reports;
      state[RECENTLY_UPDATED_REPORTS].data.unreadAction > 0 &&
        (state[RECENTLY_UPDATED_REPORTS].data.unreadAction -= 1);
      state.totalUnreadReport.data > 0 && (state.totalUnreadReport.data -= 1);
    },
    notifyReport(state, action) {
      if (state[RECENTLY_UPDATED_REPORTS].data.totalCount >= 0) {
        const newReport = action.payload;

        state[RECENTLY_UPDATED_REPORTS].data.items.unshift(newReport);
        state[RECENTLY_UPDATED_REPORTS].data.unreadAction += 1;
        state[RECENTLY_UPDATED_REPORTS].data.totalCount += 1;
      }
    },
    notifyNewsReport(state, action) {
      const { props, data } = action.payload;

      if (state[props].data.totalCount >= 0) {
        state[props].data.items = state[props].data.items.filter(
          ({ id }) => id !== data.id,
        );
        state[props].data.items.unshift(data);
      }
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = NewsReportsSilece;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getReportsByPropRequest,
  getReportsByPropSuccess,
  getReportsByPropFailure,
  getTotalUnreadReportRequest,
  getTotalUnreadReportSuccess,
  getTotalUnreadReportFailure,
  updateReadReport,
  notifyReport,
  notifyNewsReport,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const reportsByPropSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
export const totalUnreadReportSelector = createSelector(
  rootSelector,
  ({ totalUnreadReport }) => totalUnreadReport,
);
