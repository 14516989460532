import React, { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { OPU_ACTIVATED } from 'constants/storageKeys';
import { API_STATUS } from 'constants/apis';

import { getStorage } from 'services/storage';
import { isNullOrUndefined } from 'services/format/number';

import useQuery from 'hooks/useQuery';
import useOPU from 'hooks/useOPU';

import { socketSelector, subscribeNotificationRequest } from 'slices/socket';

import ToastContext from 'context/ToastContext';

import routes from '../../../routes';

import Routes from '../../router/Routes';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import BToast from '../BToast';

import './styles.scss';

const Main = ({ isAuthenticated, userInfo }) => {
  const dispatch = useDispatch();
  const socket = useSelector(socketSelector);
  const { toast, onHideToast } = useContext(ToastContext);

  const { opus, opuActivated, onGetOPUs, onSetOPUActivated } = useOPU();

  const query = useQuery();

  const opuName = decodeURIComponent(query.get('opuName'));

  useEffect(() => {
    if (userInfo.status === API_STATUS.SUCCEEDED) {
      onGetOPUs({ userInfo: userInfo?.data });
    }
  }, [userInfo]);

  useEffect(() => {
    if (opus.status === API_STATUS.SUCCEEDED && !opuActivated?.id) {
      if (opus.data.length > 0) {
        const storageOPU = getStorage(OPU_ACTIVATED);
        let opu = opus.data[0];

        if (storageOPU) {
          opu =
            opus.data.find(({ id }) => id === storageOPU.id) || opus.data[0];
        }

        if (!isNullOrUndefined(opuName)) {
          opu = opus.data.find(({ name }) => name === opuName) || opus.data[0];
        }
        onSetOPUActivated(opu);
      } else {
        onSetOPUActivated({});
      }
    }
  }, [opus.status, opuName]);

  useEffect(() => {
    if (socket?.status === API_STATUS.SUCCEEDED && opuActivated?.id) {
      dispatch(
        subscribeNotificationRequest({ notificationId: opuActivated?.id }),
      );
    }
  }, [socket?.status, opuActivated?.id]);

  return (
    <>
      <LeftSidebar
        opus={opus?.data}
        opuActivated={opuActivated}
        onSetOPUActivated={onSetOPUActivated}
      />
      <main className="pvda-main">
        <Header userInfo={userInfo?.data} />
        <div className="pvda-main__body">
          <Routes routes={routes} isAuthenticated={isAuthenticated} />
        </div>
        <BToast {...toast} onHide={onHideToast} />
      </main>
      <RightSidebar
        isAuthenticated={isAuthenticated}
        userInfo={userInfo?.data}
        opuActivated={opuActivated}
      />
    </>
  );
};

Main.propTypes = {
  isAuthenticated: PropTypes.bool,
  userInfo: PropTypes.object,
};

Main.defaultProps = {
  isAuthenticated: false,
  userInfo: {},
};

export default memo(Main);
