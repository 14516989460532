import { call, put, delay, takeEvery } from 'redux-saga/effects';

import {
  GET_CATEGORY_TYPES_PATH,
  ADD_CATEGORY_TYPE_PATH,
} from 'constants/apiPaths';
import { SOMETHING_WENT_WRONG } from 'constants/messages';

import { getData, postData } from 'services/api';

import {
  getCategoryTypesRequest,
  getCategoryTypesSuccess,
  getCategoryTypesFailure,
  addNewCategoryTypeRequest,
  addNewCategoryTypeSuccess,
  addNewCategoryTypeFailure,
} from '../slices/categoryType';

export function* getCategoryTypes() {
  try {
    const { data } = yield call(getData, { url: GET_CATEGORY_TYPES_PATH });
    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(getCategoryTypesSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(getCategoryTypesFailure(message));
  }
}

export function* addNewCategoryType({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: ADD_CATEGORY_TYPE_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(addNewCategoryTypeSuccess());

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(addNewCategoryTypeFailure(responseMessage?.Message || message));
  }
}

export default function* categoryTypeSaga() {
  yield takeEvery(getCategoryTypesRequest().type, getCategoryTypes);
  yield takeEvery(addNewCategoryTypeRequest().type, addNewCategoryType);
}
