import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';

import { getData, postData, deleteData, patchData } from 'services/api';

import {
  FUNCTION_PATH,
  GET_SUGGEST_FUNCTIONS,
  GET_FUNCTIONS,
  GET_FUNCTIONS_IN_REPORT,
  MANAGE_ATTACHMENTS_FUNCTIONS,
  ADD_NEW_FUNCTION_PATH,
  GET_FUNCTIONS_BY_OPU,
  DELETE_FUNCTION_ATTACHMENT,
  ADD_OR_UPDATE_EXECUTIVE_SUMMARY_PATH,
} from 'constants/apiPaths';
import { SOMETHING_WENT_WRONG } from 'constants/messages';

import {
  getFunctionsRequest,
  getFunctionsSuccess,
  getFunctionsFailure,
  getSuggestFunctionsRequest,
  getSuggestFunctionsSuccess,
  getSuggestFunctionsFailure,
  getFunctionsInReportRequest,
  getFunctionsInReportSuccess,
  getFunctionsInReportFailure,
  getFunctionsByOPURequest,
  getFunctionsByOPUSuccess,
  getFunctionsByOPUFailure,
  addFunctionsRequest,
  addFunctionsSuccess,
  addFunctionsFailure,
  deleteFunctionsRequest,
  deleteFunctionsSuccess,
  deleteFunctionsFailure,
  updateSequenceFunctionRequest,
  updateSequenceFunctionSuccess,
  updateSequenceFunctionFailure,
  manageAttachmentsRequest,
  manageAttachmentsSuccess,
  manageAttachmentsFailure,
  addNewFunctionsFailure,
  addNewFunctionsRequest,
  addNewFunctionsSuccess,
  deleteFunctionAttachmentRequest,
  deleteFunctionAttachmentSuccess,
  deleteFunctionAttachmentFailure,
  addOrUpdateExecutiveSummaryRequest,
  addOrUpdateExecutiveSummarySuccess,
  addOrUpdateExecutiveSummaryFailure,
} from '../slices/function';
import { updateTimeOfReport } from '../slices/report';

import { convertObjectToParams } from '../services/format/uri';

export function* getFunctions({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: `${GET_FUNCTIONS}${convertObjectToParams(payload?.query)}`,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(getFunctionsSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getFunctionsFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* getSuggestFunctions({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_SUGGEST_FUNCTIONS,
      params: payload?.query,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(400);
      yield put(getSuggestFunctionsSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getSuggestFunctionsFailure({
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* getFunctionsByOPU({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_FUNCTIONS_BY_OPU,
      params: payload?.query,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(400);
      yield put(getFunctionsByOPUSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getFunctionsByOPUFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* getFunctionsInReport({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_FUNCTIONS_IN_REPORT,
      params: payload?.query,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(getFunctionsInReportSuccess(Data));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getFunctionsInReportFailure({
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* addFunctions({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: FUNCTION_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 201) {
      yield delay(800);
      yield put(addFunctionsSuccess(Data));

      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(
      addFunctionsFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* deleteFunctions({ payload }) {
  try {
    const { data } = yield call(deleteData, {
      url: `${FUNCTION_PATH}/${payload?.functionId}`,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(deleteFunctionsSuccess({ functionId: payload?.functionId }));
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(deleteFunctionsFailure(message));

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* updateSequenceFunction({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: FUNCTION_PATH,
      data: payload?.data,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(
        updateSequenceFunctionSuccess({
          updatedFunctionsKPIs: payload?.updatedFunctionsKPIs,
        }),
      );
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(updateSequenceFunctionFailure(message));
  }
}

export function* manageAttachmentsFunction({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: MANAGE_ATTACHMENTS_FUNCTIONS,
      data: payload?.data,
    });
    const { Data } = data;

    yield put(manageAttachmentsSuccess(Data));
    // update updatedAt of report
    yield put(updateTimeOfReport(new Date()));

    if (payload?.callback) {
      payload?.callback(true);
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    yield put(manageAttachmentsFailure({ error: message }));

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* addNewFunctions({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: ADD_NEW_FUNCTION_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 201) {
      yield delay(800);
      yield put(addNewFunctionsSuccess(Data));

      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG, response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false, responseMessage?.Message);
    }

    yield put(
      addNewFunctionsFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* deleteFunctionAttachment({ payload }) {
  try {
    const { data } = yield call(deleteData, {
      url: `${DELETE_FUNCTION_ATTACHMENT}/${payload?.reportId}/${payload?.attachmentId}`,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(deleteFunctionAttachmentSuccess({ ...payload }));
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = SOMETHING_WENT_WRONG } = error;

    if (payload?.callback) {
      payload?.callback(false);
    }
    yield put(deleteFunctionAttachmentFailure({ error: message }));
  }
}

export function* addOrUpdateExecutiveSummary({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: ADD_OR_UPDATE_EXECUTIVE_SUMMARY_PATH,
      data: payload?.data,
    });

    const { StatusCode, IsSuccess } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(addOrUpdateExecutiveSummarySuccess(payload?.data));
    }
  } catch (error) {
    const { response, message = [] } = error;
    const errorMessage = response?.data?.Data[0]?.Message || message;

    yield put(addOrUpdateExecutiveSummaryFailure(errorMessage));
  }
}

export default function* functionSaga() {
  yield takeEvery(getFunctionsRequest().type, getFunctions);
  yield takeLatest(getSuggestFunctionsRequest().type, getSuggestFunctions);
  yield takeEvery(getFunctionsByOPURequest().type, getFunctionsByOPU);
  yield takeEvery(getFunctionsInReportRequest().type, getFunctionsInReport);
  yield takeEvery(addFunctionsRequest().type, addFunctions);
  yield takeEvery(deleteFunctionsRequest().type, deleteFunctions);
  yield takeEvery(updateSequenceFunctionRequest().type, updateSequenceFunction);
  yield takeEvery(manageAttachmentsRequest().type, manageAttachmentsFunction);
  yield takeEvery(addNewFunctionsRequest().type, addNewFunctions);
  yield takeEvery(
    deleteFunctionAttachmentRequest().type,
    deleteFunctionAttachment,
  );
  yield takeEvery(
    addOrUpdateExecutiveSummaryRequest().type,
    addOrUpdateExecutiveSummary,
  );
}
