import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API_STATUS } from 'constants/apis';

export const ROOT_STATE_NAME = 'categoryTypes';
const initialState = {
  categoryTypes: {
    data: [],
    status: API_STATUS.LOADING, // or: API_STATUS.LOADING, API_STATUS.SUCCEEDED, API_STATUS.FAILED
    error: null,
  },
  newCategoryType: {
    data: null,
    status: API_STATUS.IDLE,
    error: null,
  },
};

const categoryTypesSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getCategoryTypesRequest(state) {
      state.categoryTypes.status = API_STATUS.LOADING;
    },
    getCategoryTypesSuccess(state, action) {
      state.categoryTypes.data = action.payload;
      state.categoryTypes.status = API_STATUS.SUCCEEDED;
    },
    getCategoryTypesFailure(state, action) {
      state.categoryTypes.status = API_STATUS.FAILED;
      state.categoryTypes.data = {};
      state.categoryTypes.error = action.payload;
    },
    addNewCategoryTypeRequest(state) {
      state.newCategoryType.status = API_STATUS.LOADING;
    },
    addNewCategoryTypeSuccess(state) {
      state.newCategoryType.status = API_STATUS.SUCCEEDED;
      state.newCategoryType.error = null;
    },
    addNewCategoryTypeFailure(state, action) {
      state.newCategoryType.status = API_STATUS.FAILED;
      state.newCategoryType.error = action.payload;
    },
  },
});

const { actions, reducer } = categoryTypesSlice;

export default reducer;
export const {
  getCategoryTypesRequest,
  getCategoryTypesSuccess,
  getCategoryTypesFailure,
  addNewCategoryTypeRequest,
  addNewCategoryTypeSuccess,
  addNewCategoryTypeFailure,
} = actions;
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const categoryTypesSelector = createSelector(
  rootSelector,
  ({ categoryTypes }) => categoryTypes,
);
export const newCategoryTypeSelector = createSelector(
  rootSelector,
  state => state.newCategoryType,
);
