import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API_STATUS } from 'constants/apis';

export const ROOT_STATE_NAME = 'clarification';
const initialState = {
  clarificationsTmp: {
    kpiId: null,
    data: [],
  },
  clarifications: {
    status: API_STATUS.IDLE,
    data: { items: [] },
    error: null,
  },
  totalMessagesUnread: {
    status: API_STATUS.IDLE,
    data: 0,
    error: null,
  },
};

const clarificationSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getClarificationsRequest(state, action) {
      if (action.payload.query.pageIndex === 0) {
        state.clarifications.data = { items: [] };
        state.clarificationsTmp.kpiId = action.payload.query.kpiId;
        state.clarificationsTmp.data = [];
      }

      state.clarifications.status = API_STATUS.LOADING;
    },
    getClarificationsSuccess(state, action) {
      let items = [...action.payload.data.items];
      let { totalCount } = action.payload.data;

      if (action.payload.query.pageIndex > 0) {
        items = items.concat(state.clarifications.data.items);
        totalCount = state.clarifications.data.totalCount;
      } else if (
        action.payload.query.kpiId === state.clarificationsTmp.kpiId &&
        state.clarificationsTmp.data.length > 0
      ) {
        items = items.concat(state.clarificationsTmp.data);
      }

      state.clarifications.data = {
        ...action.payload.data,
        items,
        totalCount,
        // use to scroll
        isReciveClarification: false,
      };

      state.clarifications.status = API_STATUS.SUCCEEDED;
      state.clarifications.error = null;
    },
    getClarificationsFailure(state, action) {
      state.clarifications.status = API_STATUS.FAILED;
      state.clarifications.data = [];
      state.clarifications.error = action.payload.error;
    },
    addNewClarification(state, action) {
      const newClarification = action.payload;

      if (state.clarifications.data.totalCount >= 0) {
        state.clarifications.data.items.push(newClarification);
        state.clarifications.data.totalCount += 1;
        // use to scroll
        state.clarifications.data.isReciveClarification = true;
      } else {
        if (
          !state.clarificationsTmp.kpiId ||
          state.clarificationsTmp.kpiId !== newClarification?.payload?.kpiId
        ) {
          state.clarificationsTmp.kpiId = newClarification?.payload?.kpiId;
          state.clarificationsTmp.data = [];
        }

        state.clarificationsTmp.data.push(newClarification);
      }
    },
    getTotalMessagesUnreadRequest(state) {
      state.totalMessagesUnread.status = API_STATUS.LOADING;
    },
    getTotalMessagesUnreadSuccess(state, action) {
      state.totalMessagesUnread.data = Number(action.payload?.total);
      state.totalMessagesUnread.status = API_STATUS.SUCCEEDED;
    },
    getTotalMessagesUnreadFailure(state, action) {
      state.totalMessagesUnread.status = API_STATUS.FAILED;
      state.totalMessagesUnread.data = 0;
      state.totalMessagesUnread.error = action.payload;
    },
    addMessageUnread(state) {
      state.totalMessagesUnread.data += 1;
    },
    clearUnreadMessages(state) {
      state.totalMessagesUnread.data = 0;
    },
  },
});

export const {
  getClarificationsRequest,
  getClarificationsSuccess,
  getClarificationsFailure,
  addClarificationRequest,
  addClarificationSuccess,
  addClarificationFailure,
  addNewClarification,
  getTotalMessagesUnreadRequest,
  getTotalMessagesUnreadSuccess,
  getTotalMessagesUnreadFailure,
  addMessageUnread,
  clearUnreadMessages,
} = clarificationSlice.actions;
export default clarificationSlice.reducer;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const clarificationsSelector = createSelector(
  rootSelector,
  state => state.clarifications,
);
export const addClarificationSelector = createSelector(
  rootSelector,
  state => state.addClarification,
);
export const totalMessagesUnreadSelector = createSelector(
  rootSelector,
  state => state.totalMessagesUnread,
);
